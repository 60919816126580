import {
  useHandleClickOutside,
  useIsomorphicLayoutEffect,
} from '@rentpath/react-hooks'
import { useCallback, useEffect, useRef, useState, type ReactNode } from 'react'
import styles from './tooltip.module.css'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'

/*
 * Tooltip component attempts to fit the tooltip in the preferred position
 * before falling back to one of the alternatives.
 */
export enum TooltipPositionPreference {
  TOOLTIP_BOTTOM = 'Bottom',
  TOOLTIP_RIGHT = 'Right',
  TOOLTIP_TOP = 'Top',
  TOOLTIP_LEFT = 'Left',
}

const TOOLTIP_PREFERENCE_ORDER = [
  TooltipPositionPreference.TOOLTIP_BOTTOM,
  TooltipPositionPreference.TOOLTIP_TOP,
  TooltipPositionPreference.TOOLTIP_RIGHT,
  TooltipPositionPreference.TOOLTIP_LEFT,
]

// Distance we try to keep the tooltip box from the edges of the viewport.
const VIEWPORT_SAFETY_MARGIN = 10

// Size of the caret, from point to base.
const CARET_SIZE = 9

interface TooltipCSSProperties extends React.CSSProperties {
  '--caret-x': string
  '--caret-y': string
  '--tooltip-left': string
  '--tooltip-top': string
}

type Position = {
  x: number
  y: number
}

/**
 * Shrink the edges of the viewport. Each value is a positive number of pixels
 * to bring in the given edge.
 */
type ViewportAdjust = {
  top: number
  bottom: number
  left: number
  right: number
}

type TooltipProps = {
  /**
   * A ref to an HTML element that the tooltip caret will point to. If not
   * provided, the caret will point to the center of `containerRef`.
   */
  caretAnchorRef?: React.RefObject<HTMLElement | null>

  children: ReactNode
  className?: string

  /**
   * A ref to the HTML element that when tapped or hovered over will display the
   * tooltip.
   */
  containerRef: React.RefObject<HTMLElement | null>

  dataTid?: string

  /**
   * The preferred placement of the tooltip. Can be to the left, right, top,
   * or bottom of the HTML element defined by `containerRef`.
   *
   * This position will be used if there is enough space in the viewport to
   * display the tooltip. If not, then the remaining options are attempted
   * until a placement is found with enough space. Priority order: bottom,
   * top, right, left. Default is bottom.
   */
  positionPreference?: TooltipPositionPreference

  title?: string

  /**
   * This adjusts the edges of the viewport for tooltip placement calculations.
   * This is useful if there is a sticky positioned element, such as a footer or
   * header, that needs to be avoided because the tooltip will render below it
   * (lower z-index). Instead of rendering below the sticky element the tooltip
   * will select a different placement to render (e.g. if there is a footer
   * interfering, then the tooltip will render above `containerRef` rather than
   * below.
   */
  viewportAdjust?: ViewportAdjust
}

/**
 * The Tooltip component will show a popup on hover or click, on the container
 * element. When the mouse leaves the container or there is a click outside the
 * container, the tooltip disappears.
 *
 * The Tooltip renders using the `children` prop, so it can contain any
 * React components.
 *
 * Important usage note: the container (`containerRef`) must have:
 *
 *  `position: relative`
 *
 * set, or the tooltip will not display correctly.
 */
export function Tooltip({
  caretAnchorRef,
  children,
  className,
  containerRef,
  dataTid,
  positionPreference = TooltipPositionPreference.TOOLTIP_BOTTOM,
  title,
  viewportAdjust,
}: TooltipProps) {
  const [isVisible, setVisible] = useState(false)
  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipPositionPreference | null>(null)
  const [caretPosition, setCaretPosition] = useState<Position>({ x: 0, y: 0 })
  const tooltipRef = useRef<HTMLDivElement>(null)
  const axisAdjust = useRef<Position>({ x: 0, y: 0 })

  const hideTooltip = useCallback(
    function hideTooltip() {
      setVisible(false)
    },
    [setVisible]
  )

  const hideTooltipButton = useCallback(
    function hideTooltipButton(event: React.MouseEvent<HTMLButtonElement>) {
      event.preventDefault()
      event.stopPropagation()
      hideTooltip()
    },
    [hideTooltip]
  )

  /**
   * This effect calculates the position of the tooltip.
   */
  useIsomorphicLayoutEffect(() => {
    const container = containerRef?.current
    const tooltip = tooltipRef?.current

    if (!isVisible) return

    if (!container || !tooltip) return

    if (typeof window === 'undefined' || typeof document === 'undefined') return

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )

    if (!vh || !vw) return

    const cRect = container.getBoundingClientRect()
    const tRect = tooltip.getBoundingClientRect()

    const vRect = {
      top: Math.max(0, viewportAdjust ? viewportAdjust.top : 0),
      left: Math.max(0, viewportAdjust ? viewportAdjust.left : 0),
      right: Math.min(vw, viewportAdjust ? vw - viewportAdjust.right : vw),
      bottom: Math.min(vh, viewportAdjust ? vh - viewportAdjust.bottom : vh),
    }

    const safetyMargin = CARET_SIZE + VIEWPORT_SAFETY_MARGIN

    const matrix = {
      [TooltipPositionPreference.TOOLTIP_BOTTOM]:
        cRect.bottom + tRect.height + safetyMargin < vRect.bottom,
      [TooltipPositionPreference.TOOLTIP_TOP]:
        cRect.top - tRect.height - safetyMargin > vRect.top,
      [TooltipPositionPreference.TOOLTIP_LEFT]:
        cRect.left - tRect.width - safetyMargin > vRect.left,
      [TooltipPositionPreference.TOOLTIP_RIGHT]:
        cRect.right + tRect.width + safetyMargin < vRect.right,
    }

    const preferenceOrder = [
      positionPreference,
      ...TOOLTIP_PREFERENCE_ORDER.filter((p) => p !== positionPreference),
    ]

    // Test all the positions in preference order until one works
    const position = preferenceOrder.find((p) => matrix[p])

    switch (position) {
      case TooltipPositionPreference.TOOLTIP_LEFT:
      case TooltipPositionPreference.TOOLTIP_RIGHT:
        // need to adjust vertically. Tooltip will display as far down as
        // possible.
        axisAdjust.current = {
          x: 0,
          y: Math.max(
            0,
            cRect.top + tRect.height + VIEWPORT_SAFETY_MARGIN - vh
          ),
        }
        break

      case TooltipPositionPreference.TOOLTIP_TOP:
      case TooltipPositionPreference.TOOLTIP_BOTTOM:
        // need to adjust horizontally. Tooltip will display as far right as
        // possible.
        axisAdjust.current = {
          x: Math.max(
            0,
            cRect.left + tRect.width + VIEWPORT_SAFETY_MARGIN - vw
          ),
          y: 0,
        }
        break
    }

    if (tooltipPosition !== position) {
      setTooltipPosition(position || null)
    }
  }, [isVisible, containerRef, tooltipRef, tooltipPosition, positionPreference])

  /**
   * This effect calculates the position of the tooltip caret. The caret position
   * is just the midpoint of the caret anchor (or container, if no
   * caretAnchorRef passed in). The offsetting and orientation of the caret with
   * respect to the container and tooltip placement is done in CSS.
   */
  useIsomorphicLayoutEffect(() => {
    if (!isVisible) return
    if (typeof window === 'undefined' || typeof document === 'undefined') return
    if (!containerRef?.current) return

    const cRect = containerRef.current.getBoundingClientRect()

    if (caretAnchorRef?.current) {
      // Compute anchor relative to container. Anchor must be fully inside
      // container.
      const aRect = caretAnchorRef.current.getBoundingClientRect()

      setCaretPosition({
        x: aRect.left - cRect.left + Math.round(aRect.width / 2),
        y: aRect.top - cRect.top + Math.round(aRect.height / 2),
      })
    } else {
      // Use midpoint of container as caret anchor.
      setCaretPosition({
        x: Math.round(cRect.width / 2),
        y: Math.round(cRect.height / 2),
      })
    }
  }, [isVisible, containerRef, caretAnchorRef])

  useEffect(() => {
    const node = containerRef?.current

    if (!node) return

    function showTooltip(event: MouseEvent) {
      setVisible((currentIsVisible) => {
        if (!currentIsVisible) {
          event.stopPropagation()
          event.preventDefault()
          return true
        }
        return currentIsVisible
      })
    }

    node.addEventListener('mouseover', showTooltip)
    node.addEventListener('mouseleave', hideTooltip)
    node.addEventListener('click', showTooltip)

    return () => {
      node.removeEventListener('mouseover', showTooltip)
      node.removeEventListener('mouseleave', hideTooltip)
      node.removeEventListener('click', showTooltip)
    }
  }, [containerRef, hideTooltip])

  useHandleClickOutside(containerRef, hideTooltip, 'click', isVisible)

  if (!isVisible) return null

  const positionClass = tooltipPosition
    ? styles[`position${tooltipPosition}`]
    : null

  const style: TooltipCSSProperties = {
    '--caret-x': `${caretPosition.x + axisAdjust.current.x}px`,
    '--caret-y': `${caretPosition.y + axisAdjust.current.y}px`,
    '--tooltip-left': `${axisAdjust.current.x * -1}px`,
    '--tooltip-top': `${axisAdjust.current.y * -1}px`,
  }

  return (
    <div
      className={clsx(styles.tooltip, positionClass, className)}
      data-tid={dataTid}
      ref={tooltipRef}
      style={style}
      role="tooltip"
    >
      {Boolean(title) && <div className={styles.tooltipTitle}>{title}</div>}
      <div className={styles.tooltipContent}>{children}</div>
      <button
        data-tid="tooltip-close"
        onClick={hideTooltipButton}
        className={styles.closeButton}
      >
        <CloseIcon />
      </button>
    </div>
  )
}
