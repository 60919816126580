import { useRef, useState, useCallback, Suspense, lazy } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { LeadFormSteps } from '../enhanced-lead-form/const'
import { getEventCategoryPageSignifier } from '../tagging/get-event-category-page-signifier'
import { DialogModal } from '../dialog-modal/dialog-modal'

import styles from './lead-form-modal.module.css'
import thankYouStyles from '../thank-you/thank-you-modal.module.css'
import { yieldOrContinue } from 'main-thread-scheduling'
import { useAtom } from 'jotai'
import { leadFormModalStateAtom } from '../cta-modals/cta-modals.atom'
import { DialogBodyLoadingIndicator } from '../dialog-modal/dialog-body-loading-indicator'

type LeadFormModalProps = {
  id?: string
}

export const LEAD_FORM_MODAL_ID = 'lead-form-modal'

const LeadFormModalBody = lazy(() => import('./lead-form-modal-body'))

/**
 * Forces a reflow for Android Chrome due to a bug where the user
 * won't be able to scroll after closing the modal
 */
function refreshScrollPosition() {
  const userAgent = navigator.userAgent.toLowerCase()
  if (!/android.*chrome|chrome.*android/.test(userAgent)) {
    return
  }

  // Store current scroll position
  const scrollX = window.scrollX
  const scrollY = window.scrollY

  // Force a reflow
  document.body.style.display = 'inline-block'
  void document.body.offsetHeight
  document.body.style.display = ''

  // Restore scroll position
  window.scrollTo(scrollX, scrollY)
}

export function LeadFormModal(props: LeadFormModalProps) {
  const leadFormDialogRef = useRef<HTMLDialogElement>(null)
  const [currentStep, setCurrentStep] = useState<LeadFormSteps>(
    LeadFormSteps.formFields
  )

  const [{ listing, currentRefinementSlugs }, setLeadFormModalState] = useAtom(
    leadFormModalStateAtom
  )

  const { pathname } = useRouter()

  const handleClose = useCallback(async function handleClose() {
    await yieldOrContinue('smooth')
    leadFormDialogRef.current?.close()
    await yieldOrContinue('interactive')
  }, [])

  const onAfterModalClose = useCallback(
    async function onAfterModalClose() {
      await yieldOrContinue('smooth')
      refreshScrollPosition()
      await yieldOrContinue('interactive')
      setCurrentStep(LeadFormSteps.formFields)
      setLeadFormModalState({ listing: null })
    },
    [setCurrentStep, setLeadFormModalState]
  )

  const similarPropsDataTagSection = `similar_properties${getEventCategoryPageSignifier(
    pathname
  )}lead`

  const modalClass = clsx(styles.leadFormModal, {
    [styles.leadFormModalPanel]: currentStep === LeadFormSteps.formFields,
    [styles.enhancedLeadFormModalPanel]:
      currentStep === LeadFormSteps.elfPrompts,
    [styles.leadFormModalBody]: currentStep === LeadFormSteps.elfPrompts,
    [thankYouStyles.thankYouModal]: currentStep === LeadFormSteps.thankYou,
  })

  const dataTid = clsx({
    ['lead-form-modal']: currentStep === LeadFormSteps.formFields,
    ['enhanced-questions-modal']: currentStep === LeadFormSteps.elfPrompts,
    ['thank-you-modal']: currentStep === LeadFormSteps.thankYou,
  })

  const dataTagSection = clsx({
    ['lead_form_modal']: currentStep === LeadFormSteps.formFields,
    [similarPropsDataTagSection]: currentStep === LeadFormSteps.thankYou,
  })

  return (
    <DialogModal
      className={modalClass}
      id={LEAD_FORM_MODAL_ID}
      data-tid={dataTid}
      data-tag_section={dataTagSection}
      ref={leadFormDialogRef}
      onAfterClose={onAfterModalClose}
      data-dialog-modal
      entranceAnimation="grow"
    >
      {listing !== null && (
        <Suspense fallback={<DialogBodyLoadingIndicator />}>
          <LeadFormModalBody
            {...props}
            listing={listing}
            currentRefinementSlugs={currentRefinementSlugs}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            onClose={handleClose}
          />
        </Suspense>
      )}
    </DialogModal>
  )
}
