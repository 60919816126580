import {
  amenityRefinementMap,
  amenitySlugMap,
  amenitySlugs,
  petRefinementMap,
  petSlugs,
} from '@brand/search/refinements'
import { getAmenityAlias } from '@brand/detail/amenities'
import { Amenity } from '../../__generated__/api-types'

const MILE_EQUAL_KM = 1.60934
const RADIUS_EARTH = 6371 // Radius of the earth in km

export function getAltText(
  name?: string | null,
  index?: number | null,
  cityState?: string | null
) {
  return `${name ? `${name} - ` : ''}${
    cityState ? `${cityState} - ` : ''
  }Photo${typeof index === 'number' ? ` ${index + 1}` : ''}`
}

export function getAmenityText({
  refinementSlugs,
  amenitiesHighlighted,
}: {
  refinementSlugs: string[]
  amenitiesHighlighted: string[]
}) {
  const excludedAmenitySlugs = [
    amenitySlugMap[Amenity.AvailableNow],
    amenitySlugMap[Amenity.IncomeRestricted],
  ]

  const selectedPetPoliciesAndAmenities = (refinementSlugs ?? []).reduce<
    string[]
  >((acc, slug) => {
    // Pet policy gets listed first
    if (petSlugs.includes(slug)) {
      acc.unshift(petRefinementMap[slug].label)
    }

    if (!excludedAmenitySlugs.includes(slug) && amenitySlugs.includes(slug)) {
      acc.push(amenityRefinementMap[slug].label)
    }

    return acc
  }, [])

  const isPetPolicySelected = refinementSlugs.some((slug) =>
    petSlugs.includes(slug)
  )

  const highlightedAmenities = (amenitiesHighlighted ?? [])
    .filter((amenity) => {
      const isAmenitySelected =
        selectedPetPoliciesAndAmenities.includes(amenity)

      return isPetPolicySelected
        ? // If user selected a pet policy, don't also show the "Pet Friendly" top amenity
          amenity !== 'Pet Friendly' && !isAmenitySelected
        : // Deduplicate with selected amenities before mapping to the amenity alias.
          // Some amenity aliases are used only on PDP
          !isAmenitySelected
    })
    .map(getAmenityAlias)

  const allAmenities = [
    // Deduplicate
    ...new Set(selectedPetPoliciesAndAmenities.concat(highlightedAmenities)),
  ]

  return allAmenities.slice(0, 3).join(' • ')
}

const toRadians = (angle: number) => angle * (Math.PI / 180)

const kmToMiles = (distance: number) =>
  Number((distance / MILE_EQUAL_KM).toFixed(4))

/**
 * Full implementation of the haversine formula to calculate the
 * great circle distance between two points
 * @param {Array} coords
 * [
 *   -84.3814, longitude one
 *   33.836989, latitude one
 *   -84.368079, longitude two
 *   33.843709, latitude two
 * ]
 */
export const greatCircleDistance = (coords: number[]) => {
  const deltaLng = toRadians(coords[0] - coords[2])
  const deltaLat = toRadians(coords[1] - coords[3])
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(toRadians(coords[1])) *
      Math.cos(toRadians(coords[3])) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return kmToMiles(RADIUS_EARTH * c)
}
