'use client'

import { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { useInView } from 'react-intersection-observer'
import {
  STATIC_MAP_DEFAULT_TYPE,
  STATIC_MAP_DEAFULT_MAP_FORMAT,
} from '../map/map.const'

import styles from './static-map.module.css'

import {
  getImageSize,
  getStaticMapCenter,
  getEncodedPolygonList,
  generateMapShape,
  generateStaticMapUrl,
  generateStaticStreetUrlPath,
  GALLERY_IMAGE,
  MAP_DIMENSION,
} from './static-map-utils'
import type { Feature, Polygon, MultiPolygon } from 'geojson'

// For use in place of small images (listing card and smaller)
import noImageSmall from '@brand/static/images/no-image-small.png'
// For use in place of large images (e.g., detail page gallery photo)
import noImageLarge from '@brand/static/images/no-image-large.png'
import getSignedStaticMapUrl from './static-map-sign-url'
import { useTrackImpression } from '../../features/tagging/use-track-impression'
import { Pulse } from '../../components/pulse/pulse'

export type Location = {
  city?: string | null
  state?: string | null
  shapeFile?: Record<string, unknown> | null
  name?: string | null
  lat?: string | number | null
  lng?: string | number | null
}

export type StaticMapProps = {
  className?: string
  city?: string | null
  state?: string | null
  shapeFile?: Record<string, unknown> | null
  name?: string | null
  lat?: string | number | null
  lng?: string | number | null
  width?: number
  height?: number
  /**
   * flag to lazy load the street view when in view
   * if lazy is false, it will load it in immediately
   *
   * @default true
   */
  lazy?: boolean
  sourceWidth?: number
  sourceHeight?: number
  markerUrl?: string | null
  markerPosition?: string | null
  zoom?: number
  scale?: number
  mapType?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain'
  showMarker?: boolean
  format?: 'png' | 'jpg' | 'webp'
  onClick?: () => void
}

export type StaticStreetViewProps = {
  name?: string | null
  lat?: number | null
  lng?: number | null
  width?: number
  height?: number
  fov?: number | string
  heading?: number | string
  /**
   * flag to lazy load the street view when in view
   * if lazy is false, it will load it in immediately
   *
   * @default true
   */
  lazy?: boolean
  radius?: number | string
  source?: string
  pitch?: number | string
  fallbackImageSize: 'sm' | 'lg'
  className?: string
  onClick?: () => void
  shouldCheckMeta?: boolean
  onError?: () => void
}

export function StaticStreetView({
  className,
  lat, // Required for show picture(latitude)
  lng, // Required for show picture(longitude)
  width, // Width of the picture
  height, // Height of the picture
  name, // Name | Address of the property for alt text
  pitch, // default is 0, specify up or down angle of camera 90 straight up -90 down
  radius, // default is 50, wide angle of picture
  source, // source of picture, could be outdoor or default
  heading, // indicates by which angle camera position range from 0 to 360, default is front of closest building
  fov, // default is 90, it is zoom range from 0 to 120
  lazy = true,
  fallbackImageSize, // size of fallback image small or large
  onClick, // if an action is required, such as opening a modal dedicated to the street view
  shouldCheckMeta, // flag if meta for street view should be checked or not
  onError, // Function that will be called if street view fails to load
}: StaticStreetViewProps) {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px 0px 0px',
    triggerOnce: true,
    skip: !lazy,
  })
  const fallbackImage =
    fallbackImageSize === 'sm' ? noImageSmall.src : noImageLarge.src
  const [signedImageURL, setSignedImageUrl] = useState<string | null>(null)

  const streetViewUrlProps = useMemo(
    () => ({
      location: {
        lat,
        lng,
      },
      size: {
        width: width || GALLERY_IMAGE.width,
        height: height || GALLERY_IMAGE.height,
      },
      query: {
        pitch,
        radius,
        source,
        heading,
        fov,
      },
    }),
    [lat, lng, width, height, pitch, radius, source, heading, fov]
  )

  const imgSrc = generateStaticStreetUrlPath(streetViewUrlProps)

  useEffect(() => {
    if (inView || !lazy) {
      if (!(lat && lng)) {
        return setSignedImageUrl(fallbackImage)
      }
      if (typeof signedImageURL !== 'string') {
        getSignedStaticMapUrl(
          imgSrc,
          (url) => {
            if (!url && typeof onError === 'function') {
              return onError()
            }
            return setSignedImageUrl(url || fallbackImage)
          },
          shouldCheckMeta
        )
      }
    }
  }, [
    fallbackImage,
    imgSrc,
    lat,
    lng,
    signedImageURL,
    shouldCheckMeta,
    onError,
    inView,
    lazy,
  ])

  const altText = name || 'Static Street View Photo'

  const impressionRef = useTrackImpression('image_failed_to_load', {
    itemName:
      signedImageURL && signedImageURL !== fallbackImage
        ? 'image_replaced_with_street_view'
        : 'image_replaced_street_view_unavailable',
  })

  return signedImageURL ? (
    <Image
      ref={impressionRef}
      width={width || GALLERY_IMAGE.width}
      height={height || GALLERY_IMAGE.height}
      src={signedImageURL}
      alt={signedImageURL !== fallbackImage ? altText : 'Image Not Available'}
      className={className || styles.staticMap}
      onClick={onClick}
    />
  ) : (
    <Pulse className={className || styles.staticMap} ref={ref} />
  )
}

export function StaticMap({
  className,
  city,
  state,
  shapeFile,
  name,
  lat,
  lng,
  lazy = true,
  zoom,
  markerUrl,
  markerPosition,
  scale,
  showMarker,
  width = MAP_DIMENSION.width,
  height = MAP_DIMENSION.height,
  sourceWidth,
  sourceHeight,
  format = STATIC_MAP_DEAFULT_MAP_FORMAT,
  mapType = STATIC_MAP_DEFAULT_TYPE,
  onClick,
}: StaticMapProps) {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px 0px 0px',
    triggerOnce: true,
    skip: !lazy,
  })
  const center = useMemo(
    () =>
      getStaticMapCenter({
        city,
        state,
        lat,
        lng,
      }),
    [city, lat, lng, state]
  )

  const fallbackCenterMap = useMemo(
    () =>
      generateMapShape({
        center,
        mapShape: null,
        zoom,
      }),
    [center, zoom]
  )

  const centerOrShape = useMemo(
    () =>
      generateMapShape(
        shapeFile
          ? {
              showMarker,
              mapShape: getEncodedPolygonList(
                shapeFile as unknown as Feature<MultiPolygon | Polygon>
              ),
              center,
              zoom,
              markerUrl,
              markerPosition,
            }
          : {
              showMarker,
              center,
              markerUrl,
              markerPosition,
              zoom,
            }
      ),
    [showMarker, shapeFile, center, markerUrl, markerPosition, zoom]
  )

  const src = useMemo(
    () =>
      generateStaticMapUrl(centerOrShape, {
        size: getImageSize({
          width: sourceWidth || width,
          height: sourceHeight || height,
        }),
        mapType,
        ...(scale && { scale }),
        format,
      }),
    [
      centerOrShape,
      format,
      height,
      mapType,
      scale,
      sourceHeight,
      sourceWidth,
      width,
    ]
  )

  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    if (inView || !lazy) {
      if (width) {
        setImgSrc(src)
      }
    }
  }, [width, height, src, inView, lazy])

  return inView || !lazy ? (
    <Image
      width={width}
      height={height}
      src={imgSrc}
      priority
      unoptimized
      style={{ objectFit: 'cover' }}
      alt={name ? `${name} map` : `${city}, ${state} map`}
      onError={() => {
        setImgSrc(fallbackCenterMap)
      }}
      onClick={onClick}
      className={className || styles.staticMap}
    />
  ) : (
    <Pulse
      data-tid="static-map-pulse"
      ref={ref}
      className={className || styles.staticMap}
    />
  )
}
