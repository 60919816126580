export const AVAILABILITY_UNKNOWN = 'Availability Unknown'
export const AVAILABLE_UNITS = 'Available Units'
export const PRICE_UNAVAILABLE = 'Price Unavailable'
export const CONTACT_FOR_PRICE = 'Contact for Price'
export const MIN_VALID_PRICE_THRESHOLD = 10
export const MAGICAL_HIGH = 99999
export const PDP_FLOOR_PLAN_SECTION_ID = 'pdp-floorPlan-section'
export const floorPlanSectionTitles = [
  'Loft',
  'Studio',
  '1 Bedroom',
  '2 Bedrooms',
  '3 Bedrooms',
  '4+ Bedrooms',
] as const

export const floorPlansShort: readonly string[] = [
  'Loft',
  'Studio',
  '1 Bed',
  '2 Beds',
  '3 Beds',
  '4 Beds+',
]

export const floorPlansAbbrev: readonly string[] = [
  'Loft',
  'Studio',
  '1 Bd',
  '2 Bd',
  '3 Bd',
  '4 Bd+',
]

export const LISTHUB_AVAILABLE = 'Active'
export const LISTHUB_UNAVAILABLE = 'Inactive'
export const TODAY = 'Today'
export const AVAILABLE = 'Available'
export const AVAILABLE_NOW = 'Available Now'
export const NOT_AVAILABLE = 'Not Available'
export const SEE_FLOORPLANS = 'See Floorplans'
export const CONTACT_FOR_AVAILABILITY = 'Contact for Availability'
export const UNKNOWN = 'Contact'

export const FP_LEGAL_DISCLAIMER_START_FRAGMENT =
  'Floor plans are artist’s rendering. All dimensions are approximate. Actual product and specifications may vary in dimension or detail. Not all features are available in every rental home. Prices and availability are subject to change. Base rent is based on monthly frequency. '
export const FP_LEGAL_DISCLAIMER_MIDDLE_FRAGMENT = 'Additional fees may apply'
export const FP_LEGAL_DISCLAIMER_END_FRAGMENT =
  ', such as but not limited to package delivery, trash, water, amenities, etc. Deposits vary. Please see a representative for details.'
const FP_LEGAL_DISCLAIMER_END_FRAGMENT_BASIC_LISTING =
  'Please see a representative for details.'

export const FP_LEGAL_DISCLAIMER = `${FP_LEGAL_DISCLAIMER_START_FRAGMENT}${FP_LEGAL_DISCLAIMER_MIDDLE_FRAGMENT}${FP_LEGAL_DISCLAIMER_END_FRAGMENT}`
export const FP_LEGAL_DISCLAIMER_BASIC_LISTING = `${FP_LEGAL_DISCLAIMER_START_FRAGMENT}${FP_LEGAL_DISCLAIMER_END_FRAGMENT_BASIC_LISTING}`

export const BAREIS_DISCLAIMER = `Listing information is being provided by the BAREIS Inc., MLS. IDX information is provided exclusively for
 consumers' personal, non-commercial use and may not be used for any purpose other than to identify
 prospective properties consumers may be interested in purchasing. All data, photos, visualizations, and
 information regarding a property, including the property's compliance with state and local legal
 requirements and all measurements and calculations of area, have been obtained from various sources, and
 may include such material that has been generated by use of artificial intelligence. Such information and
 material have not been and will not be verified for accuracy by the listing broker or the multiple listing
 service, and are not guaranteed as complete, accurate or reliable. Such information and material should be
 independently reviewed and verified for accuracy. This information and material are intended for the
 personal use of consumers and may not be used for any purpose other than to identify prospective
 properties consumers may be interested in purchasing. Copyright 2025, Bay Area Real Estate Information
 Services, Inc. All Rights Reserved.`
