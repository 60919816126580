export { MOBILE_MARKER_POPUP_EMAIL_CTA_TEXT } from '@brand/search/marker-popup-cta-buttons'

export const REQUEST_TOUR = 'Request Tour'
export const SEND_MY_CONTACT_INFO = 'Send My Contact Info'
export const SEND_CONTACT_INFO = 'Send Contact Info'
export const SEND_TOUR_REQUEST = 'Send Tour Request'
export const SEND_AN_EMAIL = 'Send an Email'
export const CONTACT_INFO_SENT = 'Contact Info Sent'
export const REQUEST_SENT = 'Request Sent!'
export const EMAIL = 'Email'
export const CALL = 'Call'
export const SCHEDULE_TOUR = 'Schedule Tour'
export const CONTACT = 'Contact'
