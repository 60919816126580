/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { ThankYou_ListingFragmentDoc } from '../../thank-you/__generated__/thank-you.gql';
import { ListingPhone_ListingFragmentDoc } from '../../listing-phone/__generated__/listing-phone.gql';
import { AdTarget_ListingFragmentDoc } from '../../ads/__generated__/ad-targets.gql';
import { OfficeHours_ListingQueryFragmentDoc, OfficeHoursFieldsFragmentDoc, OfficeHourTimeFragmentDoc } from '../../detail/office-hours/__generated__/office-hours.gql';
export type LeadTracking_ListingFragment = { id: string, availabilityStatus: Types.ListingAvailabilityStatus, NV_RPL?: number | null, revenue?: number | null, propertyType?: Types.PropertyType | null, tplsource?: string | null, zScore?: number | null };

export type LeadForm_ListingFragment = { applicationUrl?: string | null, amenitiesHighlighted: Array<string>, availabilityStatus: Types.ListingAvailabilityStatus, categoryBadges: Array<string>, isBasic: boolean, isDiamondMax: boolean, leadPhoneRequired: boolean, offMarket: boolean, id: string, leasingTerms: Array<string>, listingTier?: string | null, phoneDesktopText?: string | null, phoneMobileText?: string | null, requestTourOptOut: boolean, name?: string | null, timeZoneId?: string | null, hasLeadEmails: boolean, website?: string | null, propertyType?: Types.PropertyType | null, tplsource?: string | null, isActive: boolean, phoneMobileSem?: string | null, phoneMobile?: string | null, phoneDesktop?: string | null, phoneDesktopSem?: string | null, phoneDesktopSemText?: string | null, phoneMobileSemText?: string | null, revenue?: number | null, zScore?: number | null, NV_RPL?: number | null, adTargetCode?: string | null, leadQuestions?: { ids?: Array<string> | null } | null, propertyManagementCompany?: { id?: string | null, name?: string | null } | null, location?: { city?: string | null, state?: string | null, lat?: number | null, lng?: number | null } | null, tourProviderDetails?: { types?: { selfGuided?: boolean | null, inPerson?: boolean | null, videoCall?: boolean | null } | null } | null, photos?: Array<{ id: string } | null> | null, optimizedPhotos?: Array<{ id: string }>, bedRange?: { min?: number | null, max?: number | null } | null, priceRange?: { min?: number | null, max?: number | null } | null, mitsPhone: { raw?: string | null, formatted?: string | null }, adPartners: Array<{ name: string }>, officeHours: Array<{ day: string, times: Array<{ open?: string | null, close?: string | null, comment?: string | null }> }> };

export type SubmitLeadMutationVariables = Types.Exact<{
  lead: Types.LeadSubmissionInput;
}>;


export type SubmitLeadMutation = { leadSubmission?: { success: boolean, message?: string | null } | null };

export const LeadTracking_ListingFragmentDoc = `
    fragment LeadTracking_ListingFragment on Listing {
  id
  availabilityStatus
  NV_RPL
  revenue
  propertyType
  tplsource
  zScore
}
    `;
export const LeadForm_ListingFragmentDoc = `
    fragment LeadForm_ListingFragment on Listing {
  applicationUrl
  amenitiesHighlighted
  availabilityStatus
  categoryBadges
  isBasic
  isDiamondMax
  leadPhoneRequired
  offMarket
  leadQuestions {
    ids
  }
  id
  leasingTerms
  listingTier
  phoneDesktopText
  phoneMobileText
  propertyManagementCompany {
    id
    name
    id
  }
  requestTourOptOut
  location {
    city
  }
  name
  timeZoneId
  tourProviderDetails {
    types {
      selfGuided
      inPerson
      videoCall
    }
  }
  hasLeadEmails
  website
  ...ThankYou_ListingFragment
  ...ListingPhone_ListingFragment
  ...AdTarget_ListingFragment
  ...LeadTracking_ListingFragment
  ...OfficeHours_ListingQueryFragment
}
    `;
export const SubmitLeadDocument = `
    mutation SubmitLead($lead: LeadSubmissionInput!) {
  leadSubmission(input: $lead) {
    success
    message
  }
}
    `;

    export function createSubmitLeadFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchSubmitLead(variables: SubmitLeadMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<SubmitLeadMutation, SubmitLeadMutationVariables>(SubmitLeadDocument, variables, headers);
      }
    }

