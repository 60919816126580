import { encode } from '@googlemaps/polyline-codec'
import type { Feature, Polygon, MultiPolygon, Position } from 'geojson'
import {
  STATIC_MAP_ZOOM,
  GOOGLE_MAPS_API_KEY,
  STATIC_MAP_BASE_URL,
  STATIC_MAP_STREET_BASE_URL,
  GOOGLE_STATIC_STREET_VIEW_API_KEY,
} from '../map/map.const'
import { shapeFileStyle } from '@brand/search/map.const'
import type { Location } from './static-map'

export const MAP_DIMENSION = { width: 1200, height: 250 }
export const GALLERY_IMAGE = { width: 471, height: 314 }

type MapShapeProps = {
  showMarker?: boolean
  center?: string
  markerUrl?: string | null
  mapShape?: string[] | null
  markerPosition?: string | null
  zoom?: number
}

const DEAFULT_HEX_OPACITY = 'FF'

/*
  Function which receiving ShapeFile of the location and generate list of the encoded polygons

  @param { Feature<Polygon | MultiPolygon> | null } shapeFile - shape file which may contain polygon or multipolygon
  @returns { string[] | null } return array of encoded polygon strings or null if shape empty or lack of props
*/
export const getEncodedPolygonList = (
  shapeFile?: Feature<Polygon | MultiPolygon> | null
) => {
  if (!shapeFile) {
    return null
  }

  const geometry = shapeFile.geometry

  if (geometry && Array.isArray(geometry.coordinates)) {
    if (!geometry.coordinates.length) {
      return null
    }
    if (geometry.type === 'MultiPolygon') {
      const multiPolygonCoordinates = geometry.coordinates
      if (multiPolygonCoordinates.length) {
        return multiPolygonCoordinates
          .map((multiPolygon) =>
            multiPolygon.map((polygon) => createEncodedPolygon(polygon))
          )
          .flat(1)
      }
      return null
    } else if (geometry.type === 'Polygon') {
      const polygonCoordinates = geometry.coordinates
      if (polygonCoordinates.length) {
        return polygonCoordinates.map((polygon) =>
          createEncodedPolygon(polygon)
        )
      }
      return null
    }
  }
  return null
}

/*
  Function which receiving Polygon positions coordinates,
  adjusting order of the lat/lng for Static map requirements
  and encoding it to be able to have less symbols for get url query

  @param { Position[] } polygon - polygon position ex: [lat, lng]
  @returns { string } return encoded polygon position string
*/
export const createEncodedPolygon = (polygon: Position[]) => {
  const pairsFlipped = polygon.map(([lat, lng]) => [lng, lat])
  return encode(pairsFlipped, 5)
}

/*
  Function which is define how to set center of static map
  @param { string | null } city - city of location based on which static map should be centered
  @param { string | null } state - state of location
  @param { string | number | null } lat - latitude of location
  @param { string | number | null } lng - longtitude of location

  @returns { string } return string map center
*/
export const getStaticMapCenter = ({
  city,
  state,
  lat,
  lng,
}: Partial<Location>) => (lat && lng ? `${lat},${lng}` : `${city},${state}`)

/*
  Function which is define size of image
  @param { ImageSize } args - arguments object with width and height which define image size

  @returns { string } return string map dimensions
*/
export const getImageSize = (args?: { width?: number; height?: number }) => {
  const { width, height } = args || {}
  if (width && height) {
    return `${width}x${height}`
  }
  if (!width && height) {
    return `${height}x${height}`
  }
  if (width && !height) {
    return `${width}x${width}`
  }
  return `${MAP_DIMENSION.width}x${MAP_DIMENSION.height}`
}

const MAP_STYLE_QUERY_LIMIT = 8192 // Google map url limit, here is what limit when image is broken

/*
  Function which normalize brand hex color and returns string parameters for url query to draw a static map path line
*/
export const getPathStyle = () => {
  const borderColor = shapeFileStyle.strokeColor?.replace('#', '')
  const bitColor = `0x${borderColor}${DEAFULT_HEX_OPACITY}`
  return `color:${bitColor}|weight:3`
}

/*
  Function which generate map parameters and define shape, center and center marker

  @param {
    center: string - center position if need to draw map based on some center position location
    showMarker: bool - flag which define show center marker or not,
    markerUrl: string - url of the icon if we want to show custom center marker,
    markerPosition: string - marker coordinates if need to shift marker image,
    mapShape: string[] - array of the encoded polygons,
    zoom: number - zoom parameter, only needed if center needs to be drawn, shape adjust zoom automatically based on boundaries
  }
  @returns { string } return string url part with shape or center query parameters
*/
export const generateMapShape = ({
  center,
  showMarker,
  markerUrl,
  markerPosition,
  mapShape,
  zoom,
}: MapShapeProps) => {
  const centerQueryString = `center=${center}&zoom=${zoom || STATIC_MAP_ZOOM}`

  if (mapShape?.length) {
    const mapStyleQueryString = mapShape
      .map((encodedPolygon) => `path=${getPathStyle()}|enc:${encodedPolygon}`)
      .join('&')
    return mapStyleQueryString.length < MAP_STYLE_QUERY_LIMIT
      ? mapStyleQueryString
      : centerQueryString
  }

  if (showMarker && markerUrl) {
    return `markers=${
      markerPosition ? `anchor:${markerPosition}|` : ''
    }icon:${markerUrl}|${center}&${centerQueryString}`
  }

  return centerQueryString
}

/*
  Function which generates map static map url

  @param { string } - url path with center of the map or shape
  @param { Record<string, string | number | boolean> } - args, arguments for static map which needs to be transcoded to url query params 
  @returns { string } return static map url
*/
export const generateStaticMapUrl = (
  centerOrShape: string,
  args: Record<string, string | number | boolean>
) => {
  const query = Object.keys(args)
    .map((key) => (key in args ? `${key}=${args[key]}` : ''))
    .filter((value) => Boolean(value))
    .join('&')
  return `${STATIC_MAP_BASE_URL}${centerOrShape}&${query}&key=${GOOGLE_MAPS_API_KEY}`
}

/*
  Function which generates map static street view image url

  @param {Object} args - The Static Street view arguments and settings
  @param {Object} args.size - size of the image
  @param {string | number} args.size.width - width of the image
  @param {string | number} args.size.height - height of the image
  @param {Object} args.location - location of the street view
  @param {string | number} args.location.lat - latitude of the location
  @param {string | number} args.location.lng - longitude of the location
  @param { Record<string, string | number | boolean> } - args.query, arguments for static street view that needs to be transcoded to url query params 
  @returns { string } return static map url
*/
export const generateStaticStreetUrl = ({
  location,
  size,
  query,
}: {
  size?: { width?: number; height?: number }
  location: Partial<Location>
  query?: Record<string, string | number | undefined>
}) => {
  const urlPath = generateStaticStreetUrlPath({
    location,
    size,
    query,
  })

  if (!urlPath) return ''

  return `${STATIC_MAP_STREET_BASE_URL}${urlPath}`
}

export const generateStaticStreetUrlPath = ({
  location,
  size,
  query,
}: {
  size?: { width?: number; height?: number }
  location: Partial<Location>
  query?: Record<string, string | number | undefined>
}) => {
  if (!location.lat || !location.lng) {
    return ''
  }

  const imageDimensionsString = getImageSize(size)

  const imageSize = `size=${imageDimensionsString}`
  const imageLocation = `location=${location.lat},${location.lng}`

  const imageQuery = query
    ? Object.keys(query)
        .map((key) =>
          key in query && query[key] ? `${key}=${query[key]}` : ''
        )
        .filter((value) => Boolean(value))
        .join('&')
    : null

  return `${imageSize}&${imageLocation}${
    imageQuery ? `&${imageQuery}` : ''
  }&key=${GOOGLE_STATIC_STREET_VIEW_API_KEY}`
}

export const generateSimpleLocationUrl = (
  location?: Partial<Location> | null
) => {
  if (!location?.lat && !location?.lng) return ''
  const imageLocation = `location=${location.lat},${location.lng}`

  return `${imageLocation}&key=${GOOGLE_STATIC_STREET_VIEW_API_KEY}`
}
