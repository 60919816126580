import clsx from 'clsx'
import type { TestIdProp } from '../../types'
import styles from './badge.module.css'
import type { ElementType, ReactNode } from 'react'

export type BadgeProps = {
  text?: string
  className?: string
  onClick?: () => void
  as?: ElementType
  leftElement?: ReactNode
  'data-tag_action'?: string
} & TestIdProp

const BadgeDefaultElement = 'span'

export function Badge(props: BadgeProps) {
  const Element = props.as || BadgeDefaultElement

  return Boolean(props.text) ? (
    <Element
      className={clsx(styles.badge, props.className)}
      data-tid={props['data-tid']}
      data-tag_action={props['data-tag_action']}
      onClick={props.onClick}
    >
      {props.leftElement}
      <span className={styles.promotionText}>{props.text}</span>
    </Element>
  ) : null
}
