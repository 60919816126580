import { isAvailable } from '../utils/is-available'
import type { ListingAvailabilityStatus } from '../../../__generated__/api-types'

type BasicAvailableListing = {
  isBasic?: boolean
  availabilityStatus?: ListingAvailabilityStatus
}

export function useIsEmailCtaBasicAvailableListing(
  listing?: BasicAvailableListing | null
) {
  return Boolean(
    listing &&
      listing.isBasic &&
      listing.availabilityStatus &&
      isAvailable(listing.availabilityStatus)
  )
}
