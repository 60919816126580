import type { RefObject } from 'react'
import { useEffect } from 'react'

/**
 * DO NOT USE THIS HOOK IF YOU CAN AVOID IT AS IT ATTACHES
 * THIS IS ONLY AS LAST RESORT AND SHOULD BE CONDITONALLY
 * ATTACHED TO WHATEVER IS VISIBLE / RENDERED
 */
export function useHandleClickOutside(
  ref: RefObject<HTMLElement | null>,
  onClickOutside: (e: MouseEvent | TouchEvent) => void,
  trigger: 'click' | 'mouseup' = 'mouseup',
  /**
   * do not attach event handlers if they're not
   * being used at the time
   * this is useful for components that are conditionally
   * rendered or visible which is where this is used
   * most
   *
   * @default false
   * @reason the developer should be explicit about this
   * and really try to conditionally attach these
   */
  shouldListen?: boolean
) {
  useEffect(() => {
    if (shouldListen === true) {
      function handleClickOutside(e: MouseEvent | TouchEvent) {
        const isClickOutsideElement =
          e.target instanceof Node &&
          !(ref.current?.contains(e.target) || e.target === ref.current)

        if (isClickOutsideElement) {
          onClickOutside(e)
        }
      }

      let options = {}

      if (trigger === 'mouseup') {
        // Generally prefer mouseup / touchend events since Headless UI
        // popover elements prevent click events from firing
        options = {
          capture: true,
          passive: true,
        }

        window.addEventListener('mouseup', handleClickOutside, options)
        window.addEventListener('touchend', handleClickOutside, options)
      } else {
        options = {
          capture: true,
        }

        window.addEventListener('click', handleClickOutside, options)
      }

      return () => {
        if (trigger === 'mouseup') {
          window.removeEventListener('mouseup', handleClickOutside, options)
          window.removeEventListener('touchend', handleClickOutside, options)
        } else {
          window.removeEventListener('click', handleClickOutside, options)
        }
      }
    }
  }, [onClickOutside, ref, trigger, shouldListen])
}
